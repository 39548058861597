import { Typography } from '@org-crowley/enterprise-react-component-library';

export interface CustomerCardHeaderProps {
  customerName: string;
}

export function CustomerCardHeader({ customerName }: CustomerCardHeaderProps) {
  return (
    <div className="flex items-center pb-5">
      {/* TODO: Replace css circle with icon or img */}
      <div className="mr-3 bg-gray-50 min-w-[45px] h-[45px] rounded-full"></div>
      <Typography variant="h500" className="block sm:hidden" as="h3">
        {customerName}
      </Typography>
      <Typography variant="h600" className="hidden sm:block" as="h3">
        {customerName}
      </Typography>
    </div>
  );
}
