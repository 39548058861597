import {
  Spinner,
  Typography
} from '@org-crowley/enterprise-react-component-library';

export function Loading() {
  return (
    <>
      <Spinner />
      <Typography variant="h500" as="h5" className="mt-4">
        One moment please...
      </Typography>
    </>
  );
}
