import { InputHTMLAttributes, useContext, ChangeEvent } from 'react';
import { Input } from './Input/Input';
import { MdSearch } from 'react-icons/md';
import cn from 'classnames';
import { HeaderContext } from '../contexts/HeaderContext'

export interface InputTextSearchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
}

export function InputTextSearch({
  placeholder,
  className
}: InputTextSearchProps) {
  const {searchValue, setSearchValue} = useContext(HeaderContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue ? setSearchValue(value) : null
  }

  return (
    <Input
      onChange={handleChange}
      placeholder={placeholder}
      value={searchValue}
      icon={
        <MdSearch
          size={20}
          className={cn('absolute', 'right-3', 'top-3', className)}
        />
      }
    />
  );
}
