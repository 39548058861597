import cn from 'classnames';
import { InputHTMLAttributes, ReactElement } from 'react';
import { IconType } from 'react-icons';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Example: \<MdSearch size={20} className="h-8 absolute right-2" />
   */
  icon?: ReactElement<IconType>;
}

export function Input({ icon, className, ...otherProps }: InputProps) {
  const inputElement = (
    <input
      className={cn(
        'rounded-md',
        'border',
        'bg-white',
        'hover:bg-blue-0',
        'pl-3',
        'border-silver-40 text-silver-90',
        'focus:bg-blue-0 text-black',
        'h-11',
        className
      )}
      {...otherProps}
    />
  );

  return (
    <div className="flex pr-3 hover:text-blue-80">
      {inputElement}
      {icon && <span className="relative">{icon}</span>}
    </div>
  );
}
