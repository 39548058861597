import{ createContext, MouseEventHandler } from 'react';
import { IconType } from 'react-icons';
export interface DropdownItems {
    label: string;
    icon?: IconType;
    onClick: MouseEventHandler<HTMLElement>;
    disabled?: boolean;
}
export interface HeaderContextProps {
    useSearch: boolean;
    setSearchValue?: (searchText: string) => void; 
    searchValue?: string;
    searchPlaceholder?: string;
    // Leaving the below as required for now because Typescript issues
    useSort: boolean;
    sortItems: DropdownItems[];
    filterItems: DropdownItems[];
    useFilter: boolean;
}

export const HeaderContext = createContext<HeaderContextProps>({
    useSearch: true,
    setSearchValue: () => {},
    useSort: true,
    searchValue: '',
    searchPlaceholder: 'Search',
    sortItems: [],
    filterItems: [],
    useFilter: true
});

